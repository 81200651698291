import { zodResolver } from "@hookform/resolvers/zod";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { Button } from "@/components/ui/button";
import { Form, FormItemField } from "@/components/ui/form";
import useAuthStore from "@/stores/auth-store";

import { toast } from "../ui/use-toast";
import { fetchAcceptInvite, fetchLogin, sendVerifyEmail } from "./api";

const formSchema = z.object({
  email: z
    .string()
    .email("Please enter your work email address.")
    .min(1, "Please enter your work email address."),
  password: z.string().min(10, {
    message: "Please enter your password",
  }),
});

export function LoginForm({ inviteToken }: { inviteToken: string }) {
  const router = useRouter();
  const [error, setError] = useState<string | null>(null);
  const { setUser } = useAuthStore();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [busy, setBusy] = useState(false);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      const redirect = router.query.redirect as string | undefined;
      setError(null);
      setBusy(true);
      const res = await fetchLogin(values);
      // check if email is verified
      if (res.code === "EMAIL_NOT_VERIFIED") {
        await sendVerifyEmail(values.email);
        router.push(`/signup/verify-request?email=${values.email}`);
        return;
      }

      if (res.data?.user) {
        const {
          data: { user },
        } = res;
        setUser(user);
        if (inviteToken) {
          fetchAcceptInvite(String(inviteToken))
            .then((invite) => {
              toast({ title: "Invitation to organization accepted" });
            })
            .catch((err) => {
              toast({ title: err.message });
            })
            .finally(() => router.push("/home"));
        }

        if (user.user_type === "university") {
          if (!user.org?.id) {
            router.push("/organization-setup/intro");
          } else {
            router.push(redirect || "/hosting/dashboard");
          }
          return;
        } else {
          // researcher already associated with an org or skip setup org
          if (user.skip_setup_org || user.org?.id) {
            if (user.user_type === "uni_office") {
              router.push(redirect || "/home");
              return;
            }
            router.push(redirect || "/home");
          } else {
            router.push(
              `/company-setup/intro?${redirect ? `redirect=${redirect}` : ""}`,
            );
          }
        }
      } else {
        throw new Error(res.message);
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setBusy(false);
    }
  }

  return (
    <Form {...form}>
      {!!error && (
        <div className="p3-medium mb-5 text-interface-error">{error}</div>
      )}
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
        <FormItemField
          className="text-coffee-600"
          label="Email Address"
          control={form.control}
          name="email"
          placeholder="Email Address"
          inputProps={{
            className: "placeholder:p2 p2-medium placeholder:text-lg",
          }}
        />
        <FormItemField
          className="text-coffee-600"
          label="Password"
          control={form.control}
          name="password"
          placeholder="Password"
          type="password"
          inputProps={{
            className: "placeholder:p2 p2-medium placeholder:text-lg",
          }}
        />
        <div className="p3-medium mt-4 text-right text-primary">
          <Link href="/recovery/forgot-password" className="p3-medium">
            Forgot Password?
          </Link>
        </div>
        <div>
          <Button type="submit" className="w-full" busy={busy}>
            Login
          </Button>
        </div>
      </form>
    </Form>
  );
}
