import { NextPageContext } from "next";
import Head from "next/head";
import Link from "next/link";

import { LoginForm } from "@/components/auth/login-form";
import { Card } from "@/components/ui/card";

export const getServerSideProps = async (context: NextPageContext) => {
  const { invite_token, redirect, referrer_org_id, referrer_source } =
    context.query;

  return {
    props: {
      invite_token: invite_token ? String(invite_token) : "",
      redirect: redirect ? String(redirect) : "",
      referrerOrgId: referrer_org_id ? Number(referrer_org_id) : 0,
      referrerSource: referrer_source ? String(referrer_source) : "",
    },
  };
};

interface Props {
  invite_token: string;
  redirect: string;
  referrerOrgId: string;
  referrerSource: string;
}

export default function LoginPage({
  invite_token,
  redirect,
  referrerOrgId,
  referrerSource,
}: Props) {
  const params = (() => {
    const params = new URLSearchParams();

    if (referrerOrgId) {
      params.append("referrer_org_id", referrerOrgId);
    }
    if (referrerSource) {
      params.append("referrer_source", referrerSource);
    }
    if (redirect) {
      params.append("redirect", redirect);
    }
    return params;
  })();

  return (
    <>
      <Head>
        <meta name="description" content="Login Page" />
      </Head>
      <div className="mx-auto mt-10 space-y-8 md:max-w-[580px]">
        <div className="text-center">
          <img src="/logo.svg" alt="secondlab-logo" className="mx-auto" />
          <h2 className="mt-4 text-coffee-900">Welcome to Second Lab!</h2>
          <div className="p1 mt-1 text-coffee-900">
            Sign in to your account.
          </div>
        </div>
        <Card className="border:none border-0 bg-transparent p-4 shadow-none md:bg-interface-white md:p-8">
          <div>{invite_token}</div>
          <LoginForm inviteToken={invite_token} />
        </Card>
        <div className="p3-medium text-center text-primary">
          Don’t have an account?{" "}
          <Link href={`/signup/business?${params.toString()}`} className="ml-2">
            Sign Up
          </Link>
        </div>
      </div>
    </>
  );
}
